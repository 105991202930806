.layout-dashboard {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.layout-menubar {
  flex: 0 0 auto;
}

.layout-content {
  display: flex;
  flex: 1 1 auto;
}

.layout-sidebar {
  flex: 0 0 250px;
  background-color: #f4f4f4;
  padding: 1rem;
}

.layout-main {
  flex: 1 1 auto;
  padding: 1rem;
}

.layout-main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
  padding: 2rem 2rem 2rem 2rem;
  max-width: calc(100% - 250px);
}
